<template>
  <van-empty image="error" description="页面不存在">
    <van-button round color="#009A4D" @click="$router.push('/home')"
      >回到首页</van-button
    >
    <van-button round color="#009A4D" @click="$router.go(-1)"
      >返回上一页</van-button
    >
  </van-empty>
</template>

<script>
export default {
  name: "NotFound",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.van-empty {
  height: 100%;
  ::v-deep .van-empty__bottom {
    margin-top: 100px;
    .van-button {
      margin: 0 8px;
    }
  }
}
</style>
